<template>
  <div>
    <p v-if="contragentsList" class="pogination__text">
      {{ currentPage }}-{{ Math.ceil(rows / perPage) }} из {{ filter.length }}
    </p>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="filter"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(id)="data">
          <div>{{ data.index + 1 + (currentPage - 1) * perPage }}</div>
        </template>
        <template #cell(address)="data">
          <p v-if="data.item.address">
            г.{{ data.item.address.city }}, ул.{{ data.item.address.street }}
            {{ data.item.address.apartment_number }} {{ data.item.address.number }}
          </p>
        </template>
        <template #cell(action)="data">
          <div>
            <a class="table-btn">
              <b-icon
                icon="pencil-fill"
                aria-hidden="true"
                class="mr-2"
                variant="warning"
                @click="$router.push(`/add/new-counterparty?id=${data.item.id}`)"
              />
            </a>
            <a @click="deleteHandler(data.item.id)">
              <b-icon icon="trash-fill" aria-hidden="true" variant="danger" />
            </a>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      swowItem: null,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "Номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "bin",
          label: "№ Договор",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb sm",
        },
        {
          key: "surname",
          label: "Наименование Контерагента",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        // {
        //   key: "address",
        //   label: "Адрес",
        //   sortable: true,
        //   isRowHeader: true,
        //   class: "tb-all",
        // },
        {
          key: "phone",
          label: "Телефон",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "name",
          label: "Контактное лицо",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "Действия",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      items: [],
    };
  },
  computed: {
    rows() {
      this.$emit("spinner");
      if (this.contragentsList) {
        return this.filter.length;
      }
      return this.items.length;
    },
    ...mapState(["contragentsList", "textFilter", "contragentType"]),
    filter() {
      let result = this.contragentsList;
      if (this.textFilter) {
        result = result.filter((e) => e.name?.indexOf(this.textFilter || "") > -1);
      }
      if (this.contragentType) {
        result = result.filter((e) => e.type === this.contragentType);
      }
      return result;
    },
  },
  mounted() {

  },
  created() {
    this.$store.dispatch("getContragentsList");
  },
  methods: {
    deleteHandler(employee_id) {
      let ask = confirm("Are you sure you want to delete?");
      if (ask) {
        this.$api
          .post("/web/counteragent-delete", {
            employee_id: employee_id,
            _method: "DELETE",
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getContragentsList");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
}
.pogination,
.pogination__text {
  position: absolute;
  top: -83px;
  right: -3px;
}
.pogination__text {
  right: 128px;
  top: -70px;
}
.page-item:nth-child(2) {
  display: none;
}
.page-item {
  margin-right: 18px;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
</style>
